import Splide from '@splidejs/splide';

export default class Slider {
    constructor(selecteur, options = {}) {
        this.selecteur = selecteur;
        if (!this.selecteur.isInit) {
            this.options = Object.assign(
                {
                    type: 'loop',
                    autoplay: false,
                    pagination: true,
                    arrows: true,
                    perPage: 1,
                    perMove: 1,
                    gap: '1rem',
                    breakpoints: '',
                    focus: '',
                    rewind: false,
                    classes: {
                        arrows: 'splide__arrows test',
                        arrow: 'splide__arrow',
                        prev: 'splide__arrow--prev',
                        next: 'splide__arrow--next',
                        pagination: 'splide__pagination',
                        page: 'splide__pagination__page',
                    },
                },
                options,
            );
            this.init();
            this.selecteur._slider = this;
        }
    }

    init() {
        new Splide(this.selecteur, {
            type: this.options.type,
            autoplay: this.options.autoplay,
            pagination: this.options.pagination,
            arrows: this.options.arrows,
            perPage: this.options.perPage,
            perMove: this.options.perMove,
            gap: this.options.gap,
            breakpoints: this.options.breakpoints,
            focus: this.options.focus,
            classes: this.options.classes,
            rewind: this.options.rewind,
        }).mount();
        this.selecteur.isInit = true;
    }
}
