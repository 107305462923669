import MapLocation from '../../../sassy/component/map/js/Map.js';

window.MapLocation = MapLocation;
globalThis.MapLocation = MapLocation;
// document.querySelectorAll('[data-map]').forEach((element) => {
//     const value = element.dataset['map'];

//     switch (value) {
//         default:
//             new MapLocation(element, {
//                 name: 'Horse in the city',
//                 pathMarker: 'img/svg/logo--white.svg',
//                 lat: 48.6793178,
//                 lng: 2.1675176,
//                 zoom: 13,
//             });
//             break;
//     }
// });
